import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { Layout, PageTitle, Block } from '../components'
import { container, padding, bgImage, type } from '../styles/global'
import { parseACF } from '../utils'
import { media } from '../styles/utils'

const DefaultPage = (props) => {
    const data = props?.pageContext?.data
    console.log(data)

	const renderBlocks = () => {
        if (!data.blocks) return;

		return data.blocks.map((block, i) => {
            // if (block.acf_fc_layout !== 'large_text_with_label') return false
			return (
				<Block
                    key={i}
                    index={i}
                    layout={block?.acf_fc_layout}
                    lastBlock={i+1 === data.blocks.length}
					{...block}
                />
			)
		})
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <PageTitle
                    title={data.page_title_override || data.title}
                />
                <Container>
                    {renderBlocks()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Label = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const Icon = styled.img``

// Layout

const Wrapper = styled.div`
    width: 100%;
`

const Container = styled.div`
    ${container}
    ${padding}
`


export default DefaultPage
